.container {
    width: 100%;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.start {
        align-items: start;
    }

    &.centered {
        align-items: center;
    }

    &.end {
        align-items: end;
    }
}
