.creditCard {
    width: 351px;
    height: 180px;
    padding: 35.1px;
    border-radius: 14.04px;
    background: var(--primary-color-gradient);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    .creditCardChip {
        padding: 5px 8px;
        gap: 8.77px;
        width: 50.02px;
        height: 24.57px;
        background: var(--warning-color-light);
        border-radius: 7.02px;
    }

    .creditCardInfos {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: end;
    }
}