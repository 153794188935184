
.container {
    width: 100%;

    a {
        text-decoration: none;
    }

    .viewer {
        width: 100%;
        height: 450px;
        border: 10px solid #E8ECEE;
        border-radius: 8px;
    }
}

@media screen and (max-width: 900px) {
    .container {
        .viewer {
            width: 90%;
            height: 300px;
        }
    }
}
