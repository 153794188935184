.containerWrapper {
    width: 100vw;
    height: 70px;
    display: flex;
    flex-direction: column;
    padding: 0px;
    justify-content: center;
    align-items: center;
    background-color: var(--basic-background);
  
    .containerSteps {
      width: calc(100vw - 80px);
  
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .dataContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
  
      p {
        padding: 0px;
        margin-bottom: 0px;
  
        font-family: "Nunito", sans-serif;
        font-size: 12px;
        font-weight: 300;
        text-align: left;
        letter-spacing: 0pt;
        color: var(--text-color-3);
        margin: 0px;
  
        &.titleBold {
          font-weight: 700;
          color: var(--text-color-5);
        }
      }
  
      .stepperContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: var(--width);
        // position: relative;

        .line {
          margin-top: 6px;
          height: 20px;
          width: 92px;
          margin-right: 0px;
          // position: absolute;
          // top: 10px;
          // right: 0px;
    
          @media (min-width: 100vw) {
            // width: var(--lineWidth);
            margin-left: 14px;
          }
    
          height: 1px;
          border-top: 2px dashed var(--text-color-1);
    
          &.concludedLine {
            margin-left: 14px;
            margin-top: -7.5px;
            border-top: 2px solid var(--primary-color-main);
          }
        }
      }
  
      .titleContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 680px;
        padding-top: 10px;
      }

      .lineEnding {
        margin-top: -4px;
      }
  
      .concluded {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin: 0px;
        background-color: var(--primary-color-main);
  
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
  
        padding: 2px;
  
        .stepCheck {
          color: var(--primary-color-light);
          margin: 0 auto;
          margin-bottom: 3px;
        }
      }
  
      .inProgress {
        padding: 1px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin: 0px;
        background-color: var(--primary-color-light);
      }
  
      .pending {
        padding: 1px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: var(--text-color-1);
      }
    }
  }
  