.container {
    position: relative;

    &.default {
        p, .option {
            color: var(--text-color-3);
        }
    }

    &.primary {
        p, .option {
            color: var(--primary-color-main);
        }
    }

    &.complementary {
        p, .option {
            color: var(--complementary-color-main);
        }
    }

    .field {
        width: 100%;
        position: relative;
        padding: 10px 8px 10px 8px;
        border-radius: 6px;
       // border: 1px solid var(--text-color-1);
        font-size: 14px;
        color: var(--text-color-3);
        //box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        background-color: var(--basic-background);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        cursor: pointer;

        p {
            margin: 0px;
            text-align: start;
            white-space: nowrap;
            font-weight: 400;
        }

        &:focus {
            outline: none;
        }

        &:hover {
           // border: 1px solid var(--text-color-2);
        }

        .label {
            font-size: 14px;
            color: var(--text-color-3);
            position: absolute;
            top: 10px;
            left: 8px;
            transition: all ease-in-out 400ms;
            vertical-align: middle;
            transform-origin: 0%;
            transition: all ease-in-out 400ms;
            pointer-events: none;
            background-color: var(--basic-background);
    
            &.labelValueOn {
                transform: scale(0.75) translateY(-1.7rem);
            }
        }
    }

    .field {
        input:focus + label {
            transform: scale(0.75) translateY(-1.7rem);
        }
    }

    .options {
        max-height: 150px;
        overflow-y: scroll;
        width: 180px;
        position: absolute;
        top: 40px;
        left: 0px;
        border-radius: 6px;
        border: 1px solid var(--text-color-1);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        background-color: var(--basic-background);
        z-index: 9999;

        .option {
            font-size: 14px;
            padding: 10px;
            text-align: start;
            cursor: pointer;

            &:hover {
                background-color: var(--text-color-1);
            }
        }
    }
}
