.text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &.start {
        text-align: start;
    }

    &.centered {
        text-align: center;
    }

    &.end {
        text-align: end;
    }

    &.default{
        color: var(--basic-black);
    }

    &.primary{
        color: var(--primary-color);
    }

    &.complementary{
        color: var(--complementary-color-main);
    }

    &.success{
        color: var(--success-color-light);
    }

    &.warning{
        color: var(--warning-color-light);
    }
    
    &.error{
        color: var(--error-color-light);
    }

    &.disabled{
        color: var(--text-color-2);
    }

    &.grey{
        color: var(--text-color-4);
    }

    &.white{
        color: var(--basic-white);
    }
}

