.question {
  width: 100%;
  max-width: 550px;
  padding: 10px;
  border-bottom: 2px solid var(--primary-color);

  .questionHeader {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;

      img {
          margin-right: 10px;
          cursor: pointer;
      }

      p {
          color: var(--primary-color);
          font-size: 16px;
          font-weight: bolder;
      }
  }

  .questionContent {
      padding-left: 30px;
      padding-bottom: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .question {
      width: 70%;
  }
}
