.container {
    //box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: filter 0.2s;
    p {
        padding: 0px 8px;
        text-decoration: none;
    }

    // Colors
    &.primary {
        background-color: var(--primary-color);
        color: var(--basic-white);

        &:hover {
            background-color: var(--primary-dark);
        }

        &.navBar {
          &:hover {
            text-decoration: underline;
           background-color: var(--primary-color);
          }
      }
    }

    

    &.complementary {
        background-color: var(--complementary-color-main);
        color: var(--basic-white);

        &:hover {
            background-color: var(--complementary-color-dark);
        }
    }

    &.ghost {

        &:hover {
            filter: brightness(0.9);
        }

        p, svg {
            color: var(--primary-color);
        }
    }

    &.disabled {
        background-color: var(--text-color-1);

        p {
            color: var(--text-color-3);
        }
    }

    &.success {
        background-color: var(--success-color-main);
        color: var(--basic-white);

        &:hover {
            background-color: var(--success-color-dark);
        }
    }

    &.warning {
        background-color: var(--warning-color-main);
        color: var(--basic-white);

        &:hover {
            background-color: var(--warning-color-dark);
        }
    }

    &.error {
        background-color: var(--error-color-main);
        color: var(--basic-white);

        &:hover {
            background-color: var(--error-color-dark);
        }
    }

    // Size
    &.sm {
        padding: 10px;

        p {
            font-size: 12px;
            font-weight: 600;
            margin: 0px;
        }
    }

    &.md {
        padding: 12px;

        p {
            font-size: 14px;
            font-weight: 600;
            margin: 0px;
        }
    }

    &.lg {
        padding: 14px;

        p {
            font-size: 16px;
            font-weight: 600;
            margin: 0px;
        }
    }
}

@media screen and (max-width: 900px) {
    .container {
        width: 200px !important;
    }
}
