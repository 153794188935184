.text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: 0px;

    &.default{
        color: var(--basic-black);
    }

    &.primary{
        color: var(--primary-color-main);
    }

    &.complementary{
        color: var(--complementary-color-main);
    }

    &.grey{
        color: var(--text-color-3);
    }

    &.white{
        color: var(--basic-white);
    }
}
