.copyCode {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .icon {
        margin-left: 10px;
        cursor: pointer;
    }
}

.qrCode {
    width: 170px;
    height: 170px;
    border: 3px solid var(--text-color-1);
    border-radius: 8px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}