:root {
    // Basic Color Palette
    --basic-black: #1f1d1d;
    --basic-white: #ffffff;
    --basic-grey: #F6F6F6;
    --basic-background: #fafafa;

    // Color Palette | Mãe de Deus
   
    --primary-dark: #1c66a5;
    --primary-color: #00789B;
    --primary-light: #5ca3b3;
    --primary-background: #f2fdff;
    --primary-color-gradient: linear-gradient(113.56deg, var(--primary-light) 0.64%, var(--primary-dark) 90.54%);

    // Color Palette | Text
    --text-color-6: #263238;
    --text-color-5: #37474f;
    --text-color-4: #455a64;
    --text-color-3: #78909c;
    --text-color-2: #cfd8dc;
    --text-color-1: #e8ecee;

    // Color Palette | Sucess
    --success-color-dark: #005024;
    --success-color-main: #006f38;
    --success-color-light: #009250;
    --success-color-background: #e1f3ea;

    // Color Palette | Warning
    --warning-color-dark: #eeab00;
    --warning-color-main: #fcc332;
    --warning-color-light: #ffd772;
    --warning-color-background: #FFF8E8;

    // Color Palette | Error
    --error-color-dark: #aa2929;
    --error-color-main: #eb5959;
    --error-color-light: #f97f7f;
    --error-color-background: #ffefef;
}
