.fileBox{
    display: flex;
    width: 448px;
    height: 148px;
    border: 1px dashed #78909C;
    border-radius: 6px;
    
    
    label{
        cursor: pointer;
    }
}
.fileInput{
    width: 100%;
    height: 100%;
  
    display:none;
    background-color: #333;
    cursor: pointer;
    
}

.displayImg{
    width: 100%;
    resize: contain;
    height: 100%;
    
}