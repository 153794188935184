.container {
    width: 100%;
    padding: 30px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 1000px) {
    .container {
        flex-direction: column-reverse;
        align-items: center;
    }
}
