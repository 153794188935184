.container {

    &.xxxSmall {
        margin: 2px 0px;
    }

    &.xxSmall {
        margin: 4px 0px;
    }

    &.xSmall {
        margin: 6px 0px;
    }

    &.small {
        margin: 8px 0px;
    }

    &.medium {
        margin: 12px 0px;
    }

    &.large {
        margin: 16px 0px;
    }

    &.xLarge {
        margin: 20px 0px;
    }

    &.xxLarge {
        margin: 24px 0px;
    }

    &.xxxLarge {
        margin: 32px 0px;
    }
}