.text {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    margin: 0px;

    &.default{
        color: var(--basic-black)
    }

    &.primary {
        color: var(--primary-color);
    }

    &.complementary {
        color: var(--complementary-color-main);
    }

    &.white {
        color: var(--basic-white);
    }
}

@media screen and (max-width: 900px) {
    .text {
        font-size: 20px;
    }
}
