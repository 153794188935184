.container {
    min-height: 100vh;
    max-width: 950px;
    margin: 0 auto;
    .content {
        min-height: 80vh;
        max-width: 950px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: auto;
    }
}

@media screen and (max-width: 900px) {
    .container {
        width: 90%;
    }
}