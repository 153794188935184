.copyCode {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .icon {
        margin-left: 10px;
        cursor: pointer;
    }
}