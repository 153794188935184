.container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .modal {
        background-color: white;
        padding: 16px 16px;
        border-radius: 8px;

        .header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            padding-top: 8px;

            .description {
                max-width: 404px;
                width: 90%;
                margin: 0 auto;
            }

            .close {
                position: absolute;
                top: 15px;
                right: 10px;
                cursor: pointer;
            }

            .image {
                width: 80%;
                margin: 0 auto;
            }
        }

        .body {
            width: 80%;
            margin: 0 auto;
            text-align: start;
        }

        .footer {
            width: 60%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 900px) {
    .container .modal {
        width: 80% !important;
    }
}

@media screen and (max-width: 600px) {
    .container .modal .header {
        padding-top: 40px;
    }
}
