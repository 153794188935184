.container {
  padding: 0px;

  .navbar {
      height: 80px;
      padding: 0 120px;
      background-color: var(--primary-color-main);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      img {
          width: 100px;
          cursor: pointer;

          &:hover {
              transform: scale(1.03);
          }
      }

      ul {
          display: flex;
          flex-direction: row;

          li {
              margin: 0 24px;
              list-style: none;
              color: var(--basic-white);
              font-size: 12px;
              cursor: pointer;

              &:hover {
                  transform: scale(1.03);
              }
          }
      }
  }

  .searchField {
      width: 100vw;
      height: 90px;
      background-color: var(--basic-background);
      display: flex;
      flex-direction: row;
      align-items: center;

      .cart {
          cursor: pointer;
          position: relative;

          .notification {
              position: absolute;
              bottom: -1px;
              right: 15px;
              width: 12px;
              height: 12px;
              border-radius: 20px;
              background-color: var(--error-color-main);
              color: var(--basic-white);
              font-size: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
          }
      }

      .location {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
      }

      .icons {
          width: 100%;
          padding-right: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .icon {
              margin: 0px 18px;
              cursor: pointer;
          }
      }
  }

  .banner {
      height: 550px;
      width: 100vw;
      overflow: hidden;
      position: relative;
      
      .bannerBackground {
          width: 100vw;
          height: 550px;
          object-fit: cover;
          
      }

      .bannerContent {
          width: 380px;
          position: absolute;
          top: 30%;
          left: 80px;
          text-align: start;
      }
  }

  .packages {
      height: 100vh;
      width: 100vw;
      padding: 80px 0px;
      background-color: var(--primary-color-background);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .packagesTitle {
          max-width: 348px;
      }

      .packagesRow {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .package {
              width: 250px;
              height: 460px;
              background: var(--basic-white);
              box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
              border-radius: 8px;
              margin: 0px 12px;
              position: relative;

              .packageBanner {
                  width: 250px;
                  display: flex;
                  align-items: flex-start;
                  justify-content: center;
                  border-top-left-radius: 8px;
                  border-top-right-radius: 8px;
                  overflow: hidden;
                  position: relative;

                  .packageBannerImage {
                      width: 160%;
                      height: 195px;
                      object-fit: cover;
                      object-position: left;
                      margin-top: -10px;
                  }

                  .layer {
                      width: 102%;
                      position: absolute;
                      bottom: 0px;
                      left: -2px;
                  }
              }

              .packageContent {
                  height: 150px;
                  padding: 0px 25px;
                  overflow-y: auto;
              }

              .packageFooter {
                  position: absolute;
                  left: 0;
                  right: 0;
                  bottom: 15px;
              }
          }
      }
  }

  .services {
      height: 100vh;
      width: 100vw;
      padding: 80px 0px;
      background-color: var(--basic-white);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .servicesRow {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .service {
              width: 220px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin: 20px 40px;
          }
      }
  }

  .partners {
      height: 300px;
      width: 100vw;
      background-color: var(--primary-color-background);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .partnersRow {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-top: 30px;
      }
  }

  .faq {
      height: 80vh;
      width: 100vw;
      background-color: var(--basic-white);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .footer {
      height: 250px;
      background-color: var(--primary-color);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .footerContent {
          width: 100%;
          max-width: 1041px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .footerColumn {
              width: 100%;
              padding-right: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              
              &.centered {
                  align-items: center;
              }

              &.end {
                  align-items: flex-end;
              }
          }

          .logo {
              width: 100px;
          }

          p {
              color: var(--basic-white);
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              cursor: pointer;
              margin: 0px;
              text-align: start;
          }
      }
  }
}

@media screen and (max-width: 1200px) {
  .container {
      .navbar {
          justify-content: center;

          ul {
              display: none;
          }
      }

      .banner {
          .bannerContent {
              width: 85%;
              left: 0;
              right: 0;
              top: 55%;
              margin-left: auto;
              margin-right: auto;
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
          }
      }

      .searchField {
          width: 90%;
          height: auto;
          padding: 20px 0px;
          margin: 0px auto;
          flex-direction: column-reverse;

          .location {
              margin-top: 20px;
          }

          .icons {
              display: none;
          }
      }

      .packages .packagesRow {
          justify-content: flex-start;
          padding: 5px 20px;
          overflow-x: scroll;
          width: 90vw;

          .package {
              height: 480px;

              .packageContent {
                  height: 170px;
              }
          }
      }

      .packages,
      .services {
          height: auto;
      }

      .services .servicesRow {
          flex-wrap: wrap;
      }

      .partners .partnersRow {
          height: auto;
          flex-direction: column;
      }

      .faq {
          height: auto;
          padding: 30px 0px;
      }

      .footer {
          height: auto;
          padding: 20px 0px 40px 0px;

          .footerContent {
              flex-direction: column !important;

              .footerColumn {
                  width: 80%;
                  margin: 15px 0px;

                  &.end {
                      align-items: center;
                  }
              }

              p {
                  text-align: center;
              }
          }
      }
  }
}