
.container {
    height: 70px;
    display: flex;
    padding: 0px 120px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-color);
    color: var(--basic-white);

    p, img, svg {
        cursor: pointer;
    }

    img:hover, svg:hover {
        transform: scale(1.1);
    }
}