.container {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    isolation: isolate;
    
    width: 100%;
    background: var(--basic-white);
    
    border: 1px solid var(--text-color-1);
    border-radius: 8px;

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .icon {
            cursor: pointer;
        }
    }

    .body {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}